/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
// libraries
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import QueryString from 'qs';
import { withStyles } from '@material-ui/core/styles';
import {
    object, string, shape, bool, arrayOf, func, number, oneOfType, array,
} from 'prop-types';
import { compose } from 'recompose';

import { bindActionCreators } from 'redux';
import { emitProductImpression } from '../../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
// components
import GraphqlCollectionSeoSchema from './Partials/GraphqlCollectionSeoSchema/GraphqlCollectionSeoSchema';
import GraphqlSeoHeadTags from '../../../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';

// helpers, redux, hooks
import useSWPrefetchPDP from '../../../../../gql/hooks/useSWPrefetchPDP';
import { getFeatureFlag, getFeatureFlags, getPresentationFamily } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { actions as tmActions } from '../../../../../../state/ducks/TagManager';
import { trackProductListData } from '../../../../../helpers/tracking/common/commonTrackingHelpers';
import {
    getUserSubmittedProductFilterZipcode, getLocationType, getSSRDeviceType,
} from '../../../../../../state/ducks/App/App-Selectors';
import seoOgCarousel from '../../../../../helpers/Seo/seoCarousel';

import * as app from '../../../../../../state/ducks/App';
import { getActiveABTest } from '../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import PassportEyeBrowContainer from '../../../../ContentTypeComponents/PassportBanner/PassportEyeBrowConatiner';
import LoadMoreButton from './Partials/LoadMoreButton';
import ProductBoxContainer from './Partials/ProductBoxContainer';
import { getPassportData } from '../../../../../../state/ducks/Passport/Passport-Selectors';
import validatePassport from '../../../../../helpers/validatePassport';
import usePrevious from '../../../../../gql/hooks/usePrevious';
import { withAuth } from '../../../../../../state/ducks/Member/ducks/Auth/Plugin/auth';

const brandSelectors = app.ducks.brand.selectors;

const styles = (theme) => ({
    simpleProduct: {
        flex: '0 0 50%',
        maxWidth: '50%',
        marginBottom: '30px',
        position: 'relative',
        width: '100%',
        paddingRight: '8px',
        paddingLeft: '8px',
        [theme.breakpoints.up(600)]: {
            paddingRight: '10px',
            paddingLeft: '10px',
            flex: '0 0 33.333333%',
            maxWidth: '33.333333%',
        },
    },
    dummySimpleProduct: {
        display: 'none',
        [theme.breakpoints.up(599)]: {
            display: 'block',
        },
        [theme.breakpoints.up(769)]: {
            display: 'none',
        },
    },
    simpleProduct4row: {
        flex: '0 0 50%',
        maxWidth: '50%',
        marginBottom: '30px',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        [theme.breakpoints.up(769)]: {
            flex: '0 0 25%',
            maxWidth: '25%',
        },
    },
    icBannerTwoSpots: {
        flex: '0 0 66.666666%',
        maxWidth: '66.666666%',
        [theme.breakpoints.down(600)]: {
            flex: '0 0 100%',
            maxWidth: '100%',
        },
    },
    icBannerThreeSpots: {
        flex: '0 0 100%',
        maxWidth: '100%',
        [theme.breakpoints.down(600)]: {
            flex: '0 0 100%',
            maxWidth: '100%',
        },
    },
    multiCollectionBannerICB: {
        marginBottom: '30px',
        minWidth: '100%',
    },
    loadMoreBox: {
        display: 'flex',
        justifyContent: 'center',
    },
    simpleProductFullWidthAbTest: {
        flex: '0 0 100%',
        maxWidth: '100%',
        marginBottom: '16px',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        [theme.breakpoints.up(600)]: {
            flex: '0 0 33.333333%',
            maxWidth: '33.333333%',
        },
    },
    abTestMarginBottomFloral: {
        marginBottom: '20px',
    },
    abTestFullWidthFeaturedMarginBottom: {
        marginBottom: '24px',
    },
    fullWidth: {
        maxWidth: '100%',
        overflow: 'hidden',
    },
    icBannerTwoProductSpots: {
        flex: '0 0 50%',
        maxWidth: '50%',
        [theme.breakpoints.down(600)]: {
            flex: '0 0 100%',
            maxWidth: '100%',
        },
    },
});

const buildProductDataHandler = ({
    data,
    giftBox,
    localFlorist,
}) => {
    let productData = [];
    if (data) {
        productData = data;
        if (giftBox && !localFlorist) {
            productData = productData.filter((x) => (x.availability ? x.availability.productDeliveryType !== 'FPT' : null));
        }
        if (localFlorist && !giftBox) {
            productData = productData.filter((x) => (x.availability ? x.availability.productDeliveryType !== 'GPT' : null));
        }
    }
    return {
        productData,
    };
};

const GraphqlProductContainerBody = ({
    data,
    brand,
    classes,
    categoryData,
    breadCrumbArray,
    filterData: {
        giftBox,
        localFlorist,
        options,
    },
    path,
    location,
    trackEvent,
    featureFlags,
    skipAddons,
    showMovieUpsell,
    onLoadMore,
    pageNumber,
    productPageSize,
    presentationFamily,
    loader,
    inCollectionBannerData,
    inCollectionBannerV2Data,
    ssrDeviceType,
    icSmartGiftBanner,
    emitProductImpressionEvent,
    icVideoBanner,
    leftRail,
    totalProducts,
    showMultiCollections,
    mobileSimpleProductRedesign,
    passportData,
    isCategoryPage,
    priceRangeData,
    variables,
    experiments,
    collectionPageHierarchyEnabled,
    isDeliveryTypeEnabled,
}) => {
    const [openPassportModal, setOpenPassportModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const ffIsLoadMoreOptimizationEnabled = useSelector(getFeatureFlag('is-load-more-optimization-enabled'));
    const ffIsOgSeoCarousel = useSelector(getFeatureFlag('is-og-data-carousel'));
    const ffIsLoadMoreCountShown = useSelector(getFeatureFlag('is-load-more-count-shown'));
    const multiCollectionABTestBrand = brand.domain === 'fruitbouquets' || brand.domain === 'berries';
    const searchStr = location?.search?.slice(1) || '';
    const urlParams = QueryString.parse(searchStr);
    const totalPages = categoryData?.category?.totalPages || 50;
    let productsPerRow = 3;
    const productLayout = categoryData?.content?.entries[0]?.product_layout || [];

    useEffect(() => {
        trackEvent({
            eventAction: '<<pageType>>',
            eventCategory: 'Test Impression',
            eventLabel: 'Load More - Variant',
            nonInteraction: '1',
        });
    }, []);

    let j;
    // loop thru cms data
    for (j = 0; j < productLayout.length; j += 1) {
        if (productLayout[j]?.viewport[0]?.select?.includes('Desktop')) {
            productsPerRow = productLayout[j]?.products_per_row;
        }
    }

    let { productData } = buildProductDataHandler({
        data,
        path,
        giftBox,
        localFlorist,
    });
    productData = JSON.parse(JSON.stringify(productData));
    const prevProductLength = usePrevious(productData.length);
    const prevProductDataIds = usePrevious(productData.map((product) => product.id));
    const previousVariables = usePrevious(variables);

    useEffect(() => {
        if (!ffIsLoadMoreOptimizationEnabled) {
            setLoading(false);
        }
    }, [productData.length]);

    useEffect(() => {
        // added below condition for restricting multiple clickstream product-impression event on direct loading of category page
        // root cause for event calling 2 times due to re-rendering of component as API is returning different response for image: {path:'', snipeImagePath:''} randomly.
        const newProductIds = productData
            .map((product) => product.id)
            .filter((id) => !prevProductDataIds?.includes(id));

        const isDataUnchanged = newProductIds.length === 0 && previousVariables === variables;
        const isCountUnchanged = prevProductLength === productData.length;
        if (isDataUnchanged || isCountUnchanged) {
            return;
        }

        emitProductImpressionEvent({
            productsPayload: {
                products: data,
                prevProductLength,
                productPageSize,
                productsPerRow,
                mobileBreakpoint: 600,
                priceRangeData,
                variables,
            },
            page: { type: 'category' },
            category: categoryData?.category || {},
        });
    }, [productData, variables]);

    const blocks = categoryData?.content?.entries[0]?.category_blocks || null;
    const seoContentData = categoryData?.content?.entries[0]?.seo || null;
    // this is a quick fix, subnavcollection comes from the category page query and returns by default the first 3 products of that collection
    // we use the first product from subnavcollection to feed it to the OG:IMAGE so SSR has it for the first initial render(in the page source)
    const pathName = categoryData?.subNavCollection?.products?.[0]?.image?.path || `//cdn1.${brand.domain}.com/wcsstore/${brand.identifier}/images/catalog/`;
    const imageName = categoryData?.subNavCollection?.products?.[0]?.image?.name || productData?.[0]?.image?.name || '';
    const pathWithoutCdn = pathName.slice(7, pathName.lenth);
    const smartGiftAbTest = (bannerName) => ((bannerName === 'IC Banner - Address Banner' && presentationFamily === 'flower') ? (icSmartGiftBanner === 'Variant') : true);

    const bannersDefinedInCollection = {};
    const multiCollectionBanner = {};
    let productUrlParameters = [];
    const inCollectionBlocksPosition = [];

    /*
     * Notes on Banners
     * Floral brands tend to use banners that are defined in Standard Collection content type.  Food brands are using banners
     * defined in the inCollection Banner content type.  They use diffent placement logic.  The former uses "show after product" and
     * does not suport consecutive banners.  The latter does support consecutive banners.
     */
    if (blocks) {
        blocks.forEach((block) => {
            const showProduct = block?.in_collection_banners?.show_after_product_number_for_mobile ? block.in_collection_banners.show_after_product_number_for_mobile : block.in_collection_banners?.show_after_product_number;
            const showAfterLastProduct = block?.in_collection_banners?.show_after_last_product || false;
            const videoIcBannerABTest = presentationFamily !== 'flower' ? icVideoBanner === 'Variant' : true;
            const videoIcBanner = (block?.in_collection_banners?.image?.content_type === 'video/mp4' && videoIcBannerABTest) || block?.in_collection_banners?.image?.content_type !== 'video/mp4';
            if (block) {
                if (block?.in_collection_banners?.show_after_product_number && smartGiftAbTest(block?.in_collection_banners?.name) && videoIcBanner) {
                    // show_after_product_number is an outdated name. "position" is more accurate.
                    if (ssrDeviceType === 'mobile' && !showAfterLastProduct) {
                        bannersDefinedInCollection[showProduct - 1] = block?.in_collection_banners;
                    } else if (ssrDeviceType !== 'mobile' && !showAfterLastProduct) {
                        bannersDefinedInCollection[block?.in_collection_banners?.show_after_product_number - 1] = block?.in_collection_banners;
                    } else if (showAfterLastProduct) {
                        bannersDefinedInCollection[totalProducts - 1] = block?.in_collection_banners;
                    }
                }

                if (block?.in_collection_multiple_collections_banner && ((multiCollectionABTestBrand && showMultiCollections === 'Variant') || !multiCollectionABTestBrand)) {
                    if (ssrDeviceType === 'mobile') {
                        multiCollectionBanner[block.in_collection_multiple_collections_banner.display_after_product_number_mobile] = block?.in_collection_multiple_collections_banner;
                    } else {
                        multiCollectionBanner[block.in_collection_multiple_collections_banner.display_after_product_number_desktop] = block?.in_collection_multiple_collections_banner;
                    }
                }

                if (block.product_url_parameters?.parameters?.length) {
                    productUrlParameters = block.product_url_parameters.parameters;
                }
            }
        });
    }

    // icBannerBlocks are defined on the Standard Collection entry.
    // inCollectionBanners are defined in their own "inCollection Banner" entries in cStack
    const inCollectionBanners = Object.keys(inCollectionBannerData || {}).length ? inCollectionBannerData : null;
    const inCollectionBannersV2 = Object.keys(inCollectionBannerV2Data || {}).length ? inCollectionBannerV2Data : null;
    // "In Collection Banner" content type superscedes block entries.
    const hideBannersDefinedInCollection = !!inCollectionBanners || !!inCollectionBannersV2;
    let icBannerData = [];
    if (inCollectionBanners && inCollectionBannersV2) {
        icBannerData = {
            ...inCollectionBanners,
            ...inCollectionBannersV2,
        };
    } else {
        icBannerData = inCollectionBanners || inCollectionBannersV2 || bannersDefinedInCollection;
    }
    const cards = [...productData]; // holds products and inCollection Banners, but not banners defined in a Standard Collection

    // hiding passport banner for active passport members
    const isActivePassportUser = validatePassport('isActivePassportUser', passportData);
    if (isActivePassportUser && Object.entries(icBannerData).length > 0) {
        icBannerData = Object.entries(icBannerData)
            .filter((banner) => !banner[1]?.name?.includes('Passport') && !banner[1]?.link?.title?.includes('Passport'))
            .reduce((obj, key) => {
                // eslint-disable-next-line no-param-reassign
                obj[key[0]] = key[1];
                return obj;
            }, {});
    }

    // splice in banners defined in inCollection Banner content type
    if (inCollectionBanners) {
        Object.keys(icBannerData).forEach((bannerKey) => {
            // eslint-disable-next-line no-restricted-globals
            if (!isNaN(bannerKey) && icBannerData[bannerKey]) { // if Number, not 'imoc'
                if (cards.length >= bannerKey) {
                    if (ssrDeviceType === 'mobile') {
                        inCollectionBlocksPosition.push(icBannerData[bannerKey]?.number_of_spots_to_use_for_mobile || 1);
                    } else {
                        inCollectionBlocksPosition.push(icBannerData[bannerKey]?.number_of_spots_to_use || 1);
                    }
                    cards.splice(bannerKey, 0, { isBanner: true, banner: icBannerData[bannerKey] });
                }
            }
        });
    }

    if (bannersDefinedInCollection && Object.keys(bannersDefinedInCollection || {})?.length > 0) {
        Object.keys(bannersDefinedInCollection).forEach((bannerKey) => {
            // eslint-disable-next-line no-restricted-globals
            if (!isNaN(bannerKey) && bannersDefinedInCollection[bannerKey]) {
                if (cards.length >= bannerKey) {
                    if (ssrDeviceType === 'mobile') {
                        inCollectionBlocksPosition.push(inCollectionBlocksPosition[bannerKey]?.number_of_spots_to_use_for_mobile || 1);
                    } else {
                        inCollectionBlocksPosition.push(inCollectionBlocksPosition[bannerKey]?.number_of_spots_to_use || 1);
                    }
                }
            }
        });
    }

    // function appends OG data to the SEO object and passes that object to GraphqlSeoHeadTags component
    const addOgDynamicData = () => {
        if (seoContentData !== null) {
            const seoCopy = { ...seoContentData };
            const { page_title, meta_description } = seoContentData;
            // add OPEN GRAPH DATA - this can be deleted when every collection have OG data from CMS.
            seoCopy.og = [
                { property: 'og:title', content: page_title },
                { property: 'og:type', content: 'website' },
                { property: 'og:image', content: `https://cdn1.${pathWithoutCdn}${imageName}x.jpg` },
                { property: 'og:description', content: meta_description },
                { property: 'og:url', content: `https://www.${brand.domain}.com${location?.pathname || ''}` },
            ];

            if (seoContentData.og?.length > 0) {
                // replace OG values from CMS (not all properties are in CMS in every collection)
                seoContentData.og?.forEach((item) => {
                    const indexSeoOGCopy = seoCopy.og.findIndex((itemCopy) => itemCopy.property === item.property);
                    if (indexSeoOGCopy !== -1) {
                        seoCopy.og[indexSeoOGCopy] = item;
                    } else {
                        seoCopy.og.push(item);
                    }
                });
            }
            return seoCopy;
        }
        return null;
    };

    const showProductsPerRow = (noOfSpotsForBanner, isMultiCollectionBanner = false, i, hasMobileVideoUrl) => {
        /*  abtest only
            remove if no longer needed */
        if (mobileSimpleProductRedesign === 'Variant A') {
            // dirty 18f required more margin from design
            return `${classes.simpleProductFullWidthAbTest} ${brand?.domain === '1800flowers' && classes.abTestMarginBottomFloral}`;
        }
        if (mobileSimpleProductRedesign === 'Variant B') {
            // ONLY FOR AB TEST REMOVE WHEN FINISHED
            if (hasMobileVideoUrl) {
                return `${classes.simpleProductFullWidthAbTest}`;
            }
            // temp hack only show below 13products due to IC Banner issue
            if ((i + 1 < 13) && (i + 1) % 3 === 0) {
                return `${classes.simpleProductFullWidthAbTest} ${classes.abTestFullWidthFeaturedMarginBottom}`;
            }
            return `${classes.simpleProduct}`;
        }
        /*  END abtest only
            remove if not long needed */
        const berriesProductClass = brand?.domain === 'berries' && ((ssrDeviceType !== 'tablet' && leftRail) || (ssrDeviceType === 'tablet' && categoryData?.guidedNavForm));
        const productClass = ((productsPerRow === 3 && brand?.domain !== 'berries') || berriesProductClass) ? `${classes.simpleProduct}` : `${classes.simpleProduct4row}`;

        if (noOfSpotsForBanner) {
            if (productsPerRow === 3 && noOfSpotsForBanner > 2) {
                return `${productClass} ${classes.icBannerThreeSpots} ${classes.fullWidth}`;
            }
            if (productsPerRow !== 3 && noOfSpotsForBanner > 2) {
                return `${productClass} ${classes.icBannerThreeSpots}`;
            }
            if (noOfSpotsForBanner > 1 && productsPerRow === 3) {
                return `${productClass} ${classes.icBannerTwoSpots}`;
            }
            if (noOfSpotsForBanner > 1 && productsPerRow !== 3) {
                return `${productClass} ${classes.icBannerTwoProductSpots}`;
            }
        }
        if (isMultiCollectionBanner) {
            return `${classes.multiCollectionBannerICB}`;
        }
        return productClass;
    };

    const addCategoryParametersToUrl = (url) => {
        let result = url;
        productUrlParameters.forEach((param) => {
            result = `${result}${result.indexOf('?') === -1 ? '?' : '&'}${param.parameter_name}=${param.parameter_value}`;
        });
        return result;
    };

    const inCollectionBlocks = inCollectionBlocksPosition?.length > 0 ? inCollectionBlocksPosition.reduce((a, b) => a + b, 0) : 0; // # of in collection banner we have for particular collection
    const productsToLoadOnLoadMoreClick = ffIsLoadMoreOptimizationEnabled ? 36 : 12; // no. of products loaded on clicking the load more button.
    if (inCollectionBlocks && productData?.length < totalProducts) {
        /**
         * The # of in collection banner item will be removed from the end of the products slot and when new page call trigger
         * it will inject the removed products first and remove the same amount of products again from the end
         * if the we are on last page nothing will remove and it will display all products
         *
         * eg. We are on page 1 and page 1 has 24 products + 1 In Collection banner and we have now 25 products slots
         * it will remove the # of In Collection banner from last from the product slot.
         * When the new page query trigger it will add removed items first
         * at the last page no product will remove and all items will show
         */
        productData.splice(productData.length - inCollectionBlocks, inCollectionBlocks);
        cards.splice(cards.length - inCollectionBlocks, inCollectionBlocks);
    }
    const isLoadMoreAvailable = totalProducts > productData?.length;
    // Send persist tracked products data
    if (productData.length) {
        trackProductListData({
            data,
            path,
            giftBox,
            localFlorist,
            buildProductDataHandler,
        });
    }
    // prefetch product pages of collection
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator && featureFlags && data && brand?.domain) {
        useSWPrefetchPDP(
            featureFlags,
            data,
            brand.domain,
        );
    }
    const getQueryParam = () => {
        const paramKeys = Object.keys(urlParams);
        if (paramKeys.length) {
            let queryStr = '';
            paramKeys.forEach((key) => {
                switch (key) {
                    case 'r':
                    case 'ref':
                    case 'rel':
                        queryStr = '?';
                        queryStr += `${queryStr.length > 1 ? '&' : ''}${key}=${urlParams[key]}`;
                        break;
                    default:
                        break;
                }
            });
            return queryStr;
        }
        return '';
    };
    const handleOpenPassportModal = () => {
        if (typeof document !== 'undefined' && document.getElementById('root')?.style?.position !== 'static') {
            document.getElementById('root').style.position = 'static';
        }
        setOpenPassportModal(true);
    };
    const handleClosePassportModal = () => setOpenPassportModal(false);

    if (featureFlags['is-passport-eligible-banner']) {
        trackEvent({
            eventCategory: 'Test Impression', eventAction: 'passport eligible', eventLabel: 'collection', nonInteraction: '1',
        });
    }

    // counting the videos that will render in between the products on collection
    let isVideoRender = 0;
    if (inCollectionBanners) {
        Object.keys(icBannerData).forEach((bannerKey) => {
            if (!Number.isNaN(bannerKey) && icBannerData[bannerKey]) {
                if (icBannerData[bannerKey]?.image?.content_type === 'video/mp4') {
                    isVideoRender += 1;
                }
            }
        });
    }
    const totalCardRender = cards.slice(0, cards.length - isVideoRender);
    const ProductBox = (
        <ProductBoxContainer
            isGnavEnabled={categoryData?.guidedNavForm || false}
            cards={totalCardRender}
            presentationFamily={presentationFamily}
            getQueryParam={getQueryParam}
            multiCollectionBanner={multiCollectionBanner}
            hideBannersDefinedInCollection={hideBannersDefinedInCollection}
            showProductsPerRow={showProductsPerRow}
            handleOpenPassportModal={handleOpenPassportModal}
            icBannerData={icBannerData}
            addCategoryParametersToUrl={addCategoryParametersToUrl}
            breadCrumbArray={breadCrumbArray}
            categoryData={categoryData}
            skipAddons={skipAddons}
            showMovieUpsell={showMovieUpsell}
            trackEvent={trackEvent}
            featureFlags={featureFlags}
            options={options}
            bannersDefinedInCollection={bannersDefinedInCollection}
            isCategoryPage={isCategoryPage}
            experiments={experiments}
            collectionPageHierarchyEnabled={collectionPageHierarchyEnabled}
            isDeliveryTypeEnabled={isDeliveryTypeEnabled}
        />
    );

    return (
        <>
            <GraphqlSeoHeadTags seoData={addOgDynamicData()} />
            {ffIsOgSeoCarousel && seoOgCarousel(data, brand)}
            <GraphqlCollectionSeoSchema categoryData={categoryData?.content?.entries[0]?.seo} productData={{ pathWithoutCdn, imageName }} />
            <>
                {ProductBox}
                <LoadMoreButton
                    hasMore={(pageNumber <= totalPages) && !loader}
                    isLoadMoreAvailable={isLoadMoreAvailable}
                    ssrDeviceType={ssrDeviceType}
                    path={path}
                    onLoadMore={onLoadMore}
                    productToLoad={productPageSize || productsToLoadOnLoadMoreClick}
                    loading={loading}
                    setLoading={setLoading}
                    totalProducts={productData?.length}
                    totalProductsToLoad={totalProducts}
                    ffIsLoadMoreOptimizationEnabled={ffIsLoadMoreOptimizationEnabled}
                    ffIsLoadMoreCountShown={ffIsLoadMoreCountShown}
                    tracking={{
                        eventCategory: 'Collection Page',
                        eventAction: 'Load More',
                        eventLabel: path || '<<pageType>>',
                    }}
                />
            </>
            {openPassportModal
                && (<PassportEyeBrowContainer isModalOpen={openPassportModal} handleClose={handleClosePassportModal} />)}
        </>
    );
};

GraphqlProductContainerBody.propTypes = {
    classes: object.isRequired,
    categoryData: object.isRequired,
    brand: object.isRequired,
    featureFlags: object.isRequired,
    breadCrumbArray: arrayOf(
        shape({
            title: string.isRequired,
            href: string.isRequired,
        }),
    ).isRequired,
    filterData: shape({
        localFlorist: bool,
        giftBox: bool,
        options: shape({
            highToLowBtn: bool,
            lowToHighBtn: bool,
            sellingBtn: bool,
        }),
    }).isRequired,
    path: string.isRequired,
    location: object.isRequired,
    data: arrayOf(
        shape({
            partNumber: string.isRequired,
            brand: string.isRequired,
            prices: arrayOf(
                shape({
                    __typename: string.isRequired,
                    currency: string.isRequired,
                    type: string.isRequired,
                    value: number.isRequired,
                }).isRequired,
            ).isRequired,
            skuPriceRange: shape({
                sale: arrayOf(
                    shape({
                        value: number.isRequired,
                    }),
                ),
                retail: arrayOf(
                    shape({
                        value: number.isRequired,
                    }),
                ),
            }).isRequired,
            availability: shape({
                deliveryMessage: string.isRequired,
            }).isRequired,
        }).isRequired,
    ).isRequired,
    skipAddons: bool.isRequired,
    showMovieUpsell: oneOfType([
        bool,
        shape({
            icon: shape({
                content_type: string,
                file_size: string,
                filename: string,
                is_dir: bool,
                title: string.isRequired,
                uid: string,
                url: string.isRequired,
            }).isRequired,
            products: arrayOf(
                shape({
                    sku: string.isRequired,
                    sku_description: string.isRequired,
                    sku_price: number.isRequired,
                }).isRequired,
            ).isRequired,
        }),
    ]).isRequired,
    trackEvent: func.isRequired,
    onLoadMore: func.isRequired,
    pageNumber: number.isRequired,
    productPageSize: number.isRequired,
    presentationFamily: string.isRequired,
    loader: bool.isRequired,
    inCollectionBannerData: object.isRequired,
    inCollectionBannerV2Data: object.isRequired,
    ssrDeviceType: string.isRequired,
    icSmartGiftBanner: string,
    emitProductImpressionEvent: func.isRequired,
    icVideoBanner: string,
    leftRail: bool,
    totalProducts: number.isRequired,
    showMultiCollections: string,
    mobileSimpleProductRedesign: string,
    passportData: object,
    isCategoryPage: bool,
    priceRangeData: object,
    variables: string,
    experiments: array,
    collectionPageHierarchyEnabled: bool,
    isDeliveryTypeEnabled: bool,
};
GraphqlProductContainerBody.defaultProps = {
    icSmartGiftBanner: '',
    icVideoBanner: '',
    leftRail: false,
    showMultiCollections: '',
    mobileSimpleProductRedesign: '',
    passportData: {},
    isCategoryPage: false,
    priceRangeData: {},
    variables: {},
    experiments: [],
    collectionPageHierarchyEnabled: false,
    isDeliveryTypeEnabled: false,
};
const mapStateToProps = (state) => ({
    passportData: getPassportData(state),
    brand: brandSelectors.getBrand(state),
    locationType: getLocationType(state),
    userSubmittedZip: getUserSubmittedProductFilterZipcode(state),
    featureFlags: getFeatureFlags(state),
    presentationFamily: getPresentationFamily(state),
    ssrDeviceType: getSSRDeviceType(state),
    icSmartGiftBanner: getActiveABTest('ic_smart_gift_banner')(state),
    icVideoBanner: getActiveABTest('ic_video_banner')(state),
    showMultiCollections: getActiveABTest('multiple_collection_banner')(state),
    mobileSimpleProductRedesign: getActiveABTest('mobile_simple_product_redesign')(state),
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: tmActions.trackEvent,
    emitProductImpressionEvent: bindActionCreators(emitProductImpression, dispatch),
});

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
);

export default withAuth(enhance(GraphqlProductContainerBody));
