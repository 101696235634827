/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
// libraries
import { Grid, makeStyles } from '@material-ui/core';
import {
    array,
    arrayOf, bool, func, object, shape, string,
} from 'prop-types';
import React from 'react';
import GraphqlIcBanner from './GraphqlIcBanner';
import SimpleProduct from './GraphqlSimpleProduct/GraphqlSimpleProduct';
import { withAuth } from '../../../../../../../state/ducks/Member/ducks/Auth/Plugin/auth';

const useStyles = makeStyles((theme) => ({
    productRow: {
        boxSizing: 'border-box',
        marginRight: '-8px',
        marginLeft: '-8px',
        width: 'auto',
        [theme.breakpoints.up(600)]: {
            marginRight: '-10px',
            marginLeft: '-10px',
        },
    },
    simpleProduct: {
        flex: '0 0 50%',
        maxWidth: '50%',
        marginBottom: '30px',
        position: 'relative',
        width: '100%',
        paddingRight: '8px',
        paddingLeft: '8px',
        [theme.breakpoints.up(600)]: {
            paddingRight: '10px',
            paddingLeft: '10px',
            flex: '0 0 33.333333%',
            maxWidth: '33.333333%',
        },
    },
    dummySimpleProduct: {
        display: 'none',
        [theme.breakpoints.up(599)]: {
            display: 'block',
        },
        [theme.breakpoints.up(769)]: {
            display: 'none',
        },
    },
}));

const ProductBoxContainer = ({
    isGnavEnabled,
    cards,
    presentationFamily,
    getQueryParam,
    multiCollectionBanner,
    hideBannersDefinedInCollection,
    showProductsPerRow,
    handleOpenPassportModal,
    icBannerData,
    addCategoryParametersToUrl,
    breadCrumbArray,
    categoryData,
    skipAddons,
    showMovieUpsell,
    trackEvent,
    featureFlags,
    options,
    bannersDefinedInCollection,
    isCategoryPage,
    experiments,
    collectionPageHierarchyEnabled,
    isDeliveryTypeEnabled,
}) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.productRow}>
            {/* Dummy div for tablet to move product over one position to allow position abs of GNAV */}
            {isGnavEnabled && featureFlags['is-guided-nav-widget-enabled'] ? <div className={`${classes.simpleProduct} ${classes.dummySimpleProduct}`} /> : null}
            {/* end Dummy div */}
            {
                cards.length !== 0 ? cards.map((card, i) => {
                    if (!card.isBanner) {
                        const {
                            name,
                            brand: productBrand,
                            brandId: productBrandId,
                            partNumber,
                            image,
                            seo,
                            skuPriceRange,
                            availability,
                            prices,
                            isPassportEligible,
                            isPersonalizable,
                            productSkus,
                            productType,
                            reviews,
                        } = card;
                        const displayQuickview = productType !== 'CUSTOM_ASSORTMENT' && (presentationFamily === 'food' || (!isPersonalizable && productBrand !== 'personalizationmall'));
                        const url = `${seo?.url}${getQueryParam()}` || '';
                        return (
                            <>
                                {multiCollectionBanner[i] && !hideBannersDefinedInCollection && (
                                    <Grid key={multiCollectionBanner[i]?.link?.href} item className={showProductsPerRow(multiCollectionBanner[i]?.number_of_spots_to_use, multiCollectionBanner[i].is_multiple_collections_banner)}>
                                        <GraphqlIcBanner
                                            icBannerBlock={multiCollectionBanner[i]}
                                            presentation_family={presentationFamily}
                                            handleOpenPassportModal={handleOpenPassportModal}
                                        />
                                    </Grid>
                                )}
                                <Grid item key={`${name}-${partNumber}`} className={showProductsPerRow(null, false, i)}>
                                    {/* for the product not available flow we need to know whether the simple product component was rendered on a category page vs the homepage or any other location */}
                                    {/* to do this we check whether the product container got categoryData as opposed to some other kind of graphql data */}
                                    <SimpleProduct
                                        quickview={displayQuickview} // disabled for products that are personalizable / CYO
                                        partNumber={partNumber}
                                        name={name}
                                        prices={prices}
                                        image={image}
                                        url={icBannerData[i] ? url : addCategoryParametersToUrl(url)}
                                        skuPriceRange={skuPriceRange}
                                        availability={availability}
                                        breadCrumbArray={breadCrumbArray}
                                        fromCategoryPage={typeof categoryData === 'object'}
                                        productReviews={reviews}
                                        categoryName={categoryData?.category?.shortDescription || categoryData?.category?.name}
                                        categoryId={categoryData?.category?.id}
                                        categoryLegacyId={categoryData?.category?.legacyId}
                                        skipAddons={skipAddons || false}
                                        showMovieUpsell={showMovieUpsell || false}
                                        track
                                        trackEvent={trackEvent}
                                        featureFlags={featureFlags}
                                        presentation_family={presentationFamily}
                                        isPassportEligible={isPassportEligible}
                                        shouldRequestEarliestDeliveryDate={i < 12}
                                        nextEarliestDeliveryDateLoad={i >= 12 && i < 48}
                                        productSkus={productSkus}
                                        filterOptions={options}
                                        productBrand={productBrandId}
                                        categoryData={categoryData}
                                        positionInCategory={i}
                                        showSimpleProductRedesignAbTest
                                        isCategoryPage={isCategoryPage}
                                        productType={productType}
                                        experiments={experiments}
                                        collectionPageHierarchyEnabled={collectionPageHierarchyEnabled}
                                        isDeliveryTypeEnabled={isDeliveryTypeEnabled}
                                    />
                                </Grid>
                                {/* Show banners (and other collection blocks) after product if defined on collection page (in cStack) instead of in inCollection Banner */}
                                {Object.keys(bannersDefinedInCollection).length > 0 && !hideBannersDefinedInCollection && icBannerData[i] && (
                                    <Grid
                                        key={icBannerData[i]?.link?.href}
                                        item
                                        // mobile_video_image.url ONLY USED FOR ABTEST remove when finished as well as the function parameter
                                        className={showProductsPerRow(icBannerData[i]?.number_of_spots_to_use, icBannerData[i]?.is_multiple_collections_banner, i, icBannerData[i]?.mobile_video_image?.url)}
                                    >
                                        <GraphqlIcBanner
                                            icBannerBlock={icBannerData[i]}
                                            presentation_family={presentationFamily}
                                            handleOpenPassportModal={handleOpenPassportModal}
                                        />
                                    </Grid>
                                )}
                            </>
                        );
                    }
                    // display banners from inCollection Banner Type
                    if (card.isBanner) {
                        return (
                            <Grid key={card?.link?.href} item className={showProductsPerRow(card.banner?.number_of_spots_to_use, card.banner?.is_multiple_collections_banner)}>
                                <GraphqlIcBanner
                                    icBannerBlock={card.banner}
                                    presentation_family={presentationFamily}
                                    handleOpenPassportModal={handleOpenPassportModal}
                                />
                            </Grid>
                        );
                    }
                    return <></>;
                }) : null
            }
        </Grid>
    );
};

ProductBoxContainer.propTypes = {
    isGnavEnabled: bool.isRequired,
    cards: arrayOf(
        shape({
            name: string.isRequired,
            brand: string.isRequired,
            brandId: string.isRequired,
            partNumber: string.isRequired,
        }),
    ).isRequired,
    presentationFamily: string.isRequired,
    getQueryParam: func.isRequired,
    multiCollectionBanner: arrayOf(shape({
        link: shape({
            href: string,
        }),
    })),
    hideBannersDefinedInCollection: bool.isRequired,
    showProductsPerRow: func.isRequired,
    handleOpenPassportModal: func.isRequired,
    icBannerData: arrayOf(shape({
        link: shape({
            href: string,
        }),
    })),
    addCategoryParametersToUrl: func.isRequired,
    breadCrumbArray: array,
    categoryData: shape({
        category: shape({
            name: string,
        }),
    }),
    skipAddons: bool.isRequired,
    showMovieUpsell: bool.isRequired,
    trackEvent: func.isRequired,
    featureFlags: object.isRequired,
    options: shape({
        highToLowBtn: bool,
        lowToHighBtn: bool,
        sellingBtn: bool,
    }),
    bannersDefinedInCollection: object,
    isCategoryPage: bool,
    experiments: array,
    collectionPageHierarchyEnabled: bool,
    isDeliveryTypeEnabled: bool,
};
ProductBoxContainer.defaultProps = {
    options: {},
    bannersDefinedInCollection: {},
    categoryData: {},
    breadCrumbArray: [],
    icBannerData: [],
    multiCollectionBanner: [],
    isCategoryPage: false,
    experiments: [],
    collectionPageHierarchyEnabled: false,
    isDeliveryTypeEnabled: false,
};
export default withAuth(ProductBoxContainer);
